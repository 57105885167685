.Home {
    max-width: 800px;
    margin: auto;
    padding: 20px;
}

.stats {
    display: flex;
    justify-content: space-evenly;
    gap: 16px;
    margin: 16px 0;
}

.stats > div {
    background: #e6fffb;
    width: 100%;
    border-radius: 16px;
    padding: 16px;
    display: flex;
    gap: 8px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.stats > div > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.stats p {
    margin-bottom: 0 !important;
    font-weight: bold;
    font-size: 14px;
    width: 100%;
    text-align: center;
}

.stats .small {
    font-size: 12px;
    font-weight: normal !important;
}

@media (max-width: 800px) {
    .stats {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    }
}

.neo-holdings {
    display: flex;
    gap: 16px;
    padding: 16px;
    border-radius: 16px;
    background: #e6fffb;
    margin-bottom: 16px;
    align-items: center;
    justify-content: center;
}

.neo-holdings p {
    margin-bottom: 0;
}

.table {
    border-radius: 16px;
    overflow: hidden;
}

.disclaimer {
    margin-top: 16px;
    background: #e6fffb;
    border-radius: 16px;
    padding: 16px;
    font-size: 14px;
}